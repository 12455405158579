// import React from 'react';
// import axiosInstance from 'api';
// import { Row, Col, Button } from 'reactstrap';
// import 'components/custom.css';
// import { setAlert } from "action/index.js";
// import { store, persistor } from "store/index";
// import Speech from "speak-tts";
// class Dictionary extends React.Component {
//   state = {
//     words: [],
//     message: ''
//   };

//   componentDidMount() {
//     this.fetchAllWords();
//   }

//   fetchAllWords = async () => {
//     try {
//       const response = await axiosInstance.get('/dictionary');
//       this.setState({
//         words: response.data.words,
//       });
//     } catch (error) {
//       console.error('Error fetching words:', error);
//     }
//   };
//   pronunciation = (word) => {
//     const speech = new Speech();
//     speech.speak({
//       text: word
//     });
//   }

//   handleDeleteWord = async (word) => {
//     try {
//       const response = await axiosInstance.delete('/removeWord/dictionary', {
//         data: { word }
//       });
//       store.dispatch(
//         setAlert({ flag: true, type: 1, message: response.data.message })
//       );
//       this.fetchAllWords(); // Refresh the word list
//     } catch (error) {
//       console.error('Error deleting word:', error);
//       this.setState({
//         message: 'An error occurred. Please try again.',
//       });
//     }
//   };

//   render() {
//     const { words, message } = this.state;
//     return (
//       <div className="content">
//         <h2>My Dictionary</h2>
//         {message && <p>{message}</p>}
//         <Row className="mb-2" style={{
//             borderBottom:  '1px solid #ccc' ,
//             paddingBottom: '10px',
//             marginBottom: '10px',
//             textAlign: 'center',
//           }}>
//           <Col md="4"><strong>Word</strong></Col>
//           <Col md="4"><strong>Pronunciation</strong></Col>
//           <Col md="4"><strong>Action</strong></Col>
//         </Row>
//         {words.map((word) => (
//           <Row key={word.word} className="mb-2" >
//             <Col md="4">{word.word}</Col>
//             {/* <Col md="4">{word.status === 1 ? 'Active' : 'Inactive'}</Col> */}
//             <Col md="4" style={{
//             borderBottom:  '1px solid #ccc' ,
//             paddingBottom: '10px',
//             marginBottom: '10px',
//             textAlign: 'center',
//           }}><i
//           className="fa fa-bullhorn text-speak-icon"
//           aria-hidden="true"
//           onClick={() => this.pronunciation(word.word)}
//         ></i></Col>
//             <Col md="4" style={{
//             borderBottom:  '1px solid #ccc' ,
//             paddingBottom: '10px',
//             marginBottom: '10px',
//             textAlign: 'center',
//           }}>
//               <Button color="danger" size="sm" onClick={() => this.handleDeleteWord(word.word)}>
//                 Remove
//               </Button>
//             </Col>
//           </Row>
//         ))}
//       </div>
//     );
//   }
// }

// export default Dictionary;






import React from 'react';
import axiosInstance from 'api';
import { Row, Col, Button } from 'reactstrap';
import 'components/custom.css';
import { setAlert } from "action/index.js";
import { store } from "store/index";
import Speech from "speak-tts";

class Dictionary extends React.Component {
  state = {
    words: [],
    message: ''
  };

  componentDidMount() {
    this.fetchAllWords();
  }

  fetchAllWords = async () => {
    try {
      const response = await axiosInstance.get('/dictionary');
      this.setState({
        words: response.data.words,
      });
    } catch (error) {
      console.error('Error fetching words:', error);
    }
  };

  pronunciation = (word) => {
    const speech = new Speech();
    speech.speak({
      text: word
    });
  };

  handleDeleteWord = async (word) => {
    try {
      const response = await axiosInstance.delete('/removeWord/dictionary', {
        data: { word }
      });
      store.dispatch(
        setAlert({ flag: true, type: 1, message: response.data.message })
      );
      this.fetchAllWords(); // Refresh the word list
    } catch (error) {
      console.error('Error deleting word:', error);
      this.setState({
        message: 'An error occurred. Please try again.',
      });
    }
  };

  render() {
    const { words, message } = this.state;
    return (
      <div className="content">
        <h2>My Dictionary</h2>
        {message && <p>{message}</p>}
        <Row
          className="mb-2"
          style={{
            borderBottom: '1px solid #ccc',
            paddingBottom: '10px',
            marginBottom: '10px',
          }}
        >
          <Col md="4"><strong>Word</strong></Col>
          <Col md="4" style={{ textAlign: 'center' }}><strong>Pronunciation</strong></Col>
          <Col md="4" style={{ textAlign: 'center' }}><strong>Action</strong></Col>
        </Row>
        {words.map((word) => (
          <Row
            key={word.word}
            className="mb-2"
            style={{
              borderBottom: '1px solid #ccc',
              paddingBottom: '10px',
              marginBottom: '10px',
            }}
          >
            <Col md="4">{word.word}</Col>
            <Col md="4" style={{ textAlign: 'center' }}>
              <i
                className="fa fa-bullhorn text-speak-icon"
                aria-hidden="true"
                onClick={() => this.pronunciation(word.word)}
              ></i>
            </Col>
            <Col md="4" style={{ textAlign: 'center' }}>
              <Button
                color="danger"
                size="sm"
                onClick={() => this.handleDeleteWord(word.word)}
              >
                Remove
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default Dictionary;
